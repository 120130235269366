import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import backgroundImage from "../assets/wavy-background.png";
import keepsiesLogo from "../assets/keepsies-logo-purple.svg";
import keepsiesLogoText from "../assets/keepsies-logo-text.svg";
import ClipLoader from "react-spinners/ClipLoader";


export default function DownloadData({device}) {
    const functions = getFunctions();
    const {token} = useParams();
    const [downloadUrl, setDownloadUrl] = React.useState(null);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        if (!token) return;
        httpsCallable(functions, 'consumeDataDownload')({token})
            .then((result) => {
                if (result.data.success) {
                    const url = result.data.url;
                    console.log("URL", url);
                    setDownloadUrl(url);
                    window.location.href = url;
                } else {
                    setError("Unable to validate download.");
                }
            })
            .catch((error) => {
                setError("Unable to validate download.");
            });
    }, [token]);

    async function handleDownload() {
        if (downloadUrl) {
            window.location.href = downloadUrl;
        }
    }
    
    function DesktopView({ downloadUrl }) {
        return (
            <div className="App" style={{ 
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
    
                <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '350px',
                width: '450px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '20px',
                boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {downloadUrl ? 
                    <div style={{flexDirection: "column", display: "flex", marginLeft: 16, marginRight: 16}}>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 48,
                        }}>Your data is ready!</p>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                            paddingLeft: 16, 
                            paddingRight: 16,
                        }}>Your download should begin automatically - or you can click below.</p>    
                        <div style={{
                            display: 'flex',
                            height: 58,
                            borderRadius: 29,
                            backgroundColor: "#5448F3",
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 32,
                            cursor: 'pointer'
                        }} onClick={handleDownload}>
                            <p style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: '16px',
                                color: 'white',
                            }}>Download</p>
                        </div>            
                    </div>
                    : error ? <p style={{fontFamily: "Inter"}}>{error}</p>
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!downloadUrl}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                        }}>Verifying download request...</p>
                    </div>}
                </div>
            </div>
        );
    }

    function IOSView({ downloadUrl }) {
        return (
            <div className="App" style={{ 
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
            }}>
    
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 64,
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {downloadUrl ? 
                    <div style={{flexDirection: "column", display: "flex", marginLeft: 16, marginRight: 16}}>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 48,
                        }}>Your data is ready!</p>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                            paddingLeft: 16, 
                            paddingRight: 16,
                        }}>Your download should begin automatically - or you can click below.</p>   
                        <div style={{
                            display: 'flex',
                            height: 58,
                            borderRadius: 29,
                            backgroundColor: "#5448F3",
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 32,
                        }} onClick={handleDownload}>
                            <p style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: '16px',
                                color: 'white',
                            }}>Download</p>
                        </div>            
                    </div>

                    : error ? <p style={{fontFamily: "Inter"}}>{error}</p> 
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!downloadUrl}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                        }}>Verifying download request...</p>
                    </div>}
                </div>
            </div>
        );
    }

    const Display = device === "iOS" ? IOSView : DesktopView;
    
    return <Display downloadUrl={downloadUrl} />;
}