import React, { useState, useEffect, useCallback, useRef } from 'react';
import './ResetPassword.css';
import {useParams} from 'react-router-dom';
import keepsiesLogo from "../assets/keepsies-logo-purple.svg";
import keepsiesLogoText from "../assets/keepsies-logo-text.svg";
import ClipLoader from "react-spinners/ClipLoader";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { ReactComponent as FaEye } from '../assets/icons/fontawesome/solid/eye.svg';
import { ReactComponent as FaEyeSlash } from '../assets/icons/fontawesome/solid/eye-slash.svg';


export default function({device}) {
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const {token} = useParams();
  console.log(token);
  const functions = getFunctions();

  useEffect(() => {
    async function validate() {
      try {
        const valid = await httpsCallable(functions, 'validatePasswordReset')({token});
        if (valid.data.success) {
          setEmail(valid.data.email);
          setIsValid(true);
        } else {
          setIsValid(false);
          setError("Invalid password reset token.");
        }
      } catch (e) {
        setIsValid(false);
        setError("Invalid password reset token.");
      } finally {
        setIsLoading(false);
      }
    }
    validate();
  }, [token]);
  
  function Form() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);

    useEffect(() => {
      const valid = checkPassword(password, confirmPassword);
      if (valid) setBtnDisabled(false);
      else setBtnDisabled(true);
    }, [password, confirmPassword]);

    function checkPassword(password, confirmPassword) {
      if (password.length < 6) return false;
      if (password !== confirmPassword) return false;
      if (!/[0-9!@#$%^&*]/.test(password)) return false;
      return true;
    }

    async function handleSubmit(e) {
      e.preventDefault();
      if (!isValid || btnDisabled) return;
      
      setIsLoading(true);
      window.scrollTo({ top: 0, behavior: "smooth" });

      function handleError(error) {
        setError("Password reset failed.");
        setSuccess(false);
      };
  
      try {
        const result = await httpsCallable(functions, 'resetPassword')({token, password});
        if (result.data.success) {
          setSuccess(true);
        } else {
          handleError(result.data.error);
        }
      } catch (e) {
        handleError(e);
      } finally {
        setIsLoading(false);
      }
    }

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px', marginBottom: '16px' }}>
          <h3 style={{ marginBottom: '16px' }}>Reset Password</h3>
          <p style={{ marginTop: '0px', fontFamily: "Inter" }}>Enter new password for your account</p>
          <p style={{ marginTop: '-16px' }}>{email}</p>
        </div>
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="new-password" style={{fontSize: '14px', color: "gray"}}>New Password</label>
              <input
                id="new-password"
                type={passwordShown ? "text" : "password"}
                autoFocus
                autoComplete='off'
                value={password}
                onChange={(e)=>setPassword(e.target.value)}
                required
              />
              <button 
                  type="button" onClick={()=>setPasswordShown(!passwordShown)} 
                  className="toggle-password-visibility"
                  tabIndex={-1}
              >
                  {passwordShown ? <FaEye style={{width: '18px', height: '18px'}} /> : <FaEyeSlash style={{width: '18px', height: '18px'}} fill='gray'/>}
              </button>
            </div>
            <div className="input-group">
              <input
                id="confirm-password"
                type={passwordShown ? "text" : "password"}
                placeholder='Confirm Password'
                autoComplete='off'
                value={confirmPassword}
                onChange={(e)=>setConfirmPassword(e.target.value)}
                required              
              />
            </div>
            <div className="password-instructions">
              Use a minimum of 6 characters (case sensitive) with at least one number or special character.
            </div>
            <button 
              type="submit" 
              className="submit-btn" 
              style={{fontWeight: 600}}
              disabled={btnDisabled}
            >Set New Password</button>
          </form>
        </div>
      )
  }

  function SuccessDesktop() {
    return (
      <div style={{marginBottom: '48px'}}>
        <p className="text-header">You successfully changed your password.</p>
        <p className="text-body">Return to the app to login and get to organizin'.</p>
      </div>
    )
  }

  function SuccessIOS() {
    return (
      <div style={{marginBottom: 48, marginTop: 32}}>
        <p className="text-header">You successfully changed your password.</p>
        <p className="text-body" style={{margin: 24}}>Return to the app to login and get to organizin'.</p>
        <button 
          type="button" onClick={handleDeeplink} 
          className="submit-btn"
          style={{marginTop: 32, fontWeight: 600}}
        >Return to the app</button>
      </div>
    )
  }

  function Loading({device}) {
    const offset = device === "iOS" ? 0 : -40;
    return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '455px', position: 'relative'}}>
      <div style={{position: 'relative', top: `${offset}px`}}>
        <ClipLoader color={"#5448F3"} loading={true} size={32} aria-label="Loading Spinner"/>
      </div>
    </div>
    )
  }

  function DesktopView() {
    return (
      <div className="reset-password-container">
        <div className="reset-password-card">
        <header>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 32 }}>
          <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
          <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />    
        </div>
        </header>
          {
            isLoading ? <Loading />
            : success ? <SuccessDesktop /> 
            : <Form />
          }            
        </div>
      </div>
    )
  }

  function IOSView() {
    return (
      <div style={{ 
        backgroundColor: 'white',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
      }}>

        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: 64,
        }}>
            <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
            </div>
            <div style={{margin: '16px'}}>
              {
                isLoading ? <Loading device={device}/>
                : success ? <SuccessIOS /> 
                : <Form />
              }  
            </div>
        </div>
      </div>
    )
  }

  function handleDeeplink() {
    window.location.href = "keepsies://"; //"https://web.keepsies.io/link/" <-- for universal link
  }

  const Display = device === "iOS" ? IOSView : DesktopView;

  return <Display />;
};

