import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import backgroundImage from "../assets/wavy-background.png";
import keepsiesLogo from "../assets/keepsies-logo-purple.svg";
import keepsiesLogoText from "../assets/keepsies-logo-text.svg";
import ClipLoader from "react-spinners/ClipLoader";


export default function EmailVerification({device}) {
    const functions = getFunctions();
    const {token} = useParams();
    const [verified, setVerified] = React.useState(false);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        if (!token) return;
        httpsCallable(functions, 'verifyEmail')({token})
            .then((result) => {
                if (result.data.success) {
                    setVerified(true);
                } else {
                    setError("Verification failed or already complete.");
                }
            })
            .catch((error) => {
                setError("Verification failed or already complete.");
            });
    }, [token]);

    useEffect(() => {
        if (verified || error) {
            if (device === "iOS") {
                window.scrollTo({ top: 0, behavior: "smooth" });
            }
        }
    }, [verified, error]);

    function handleToApp() {
        window.location.href = "keepsies://?emailVerified=true";
    }
    
    function DesktopView({ verified }) {
        return (
            <div className="App" style={{ 
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
    
                <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '350px',
                width: '450px',
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
                borderRadius: '20px',
                boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {verified ? 
                    <div style={{flexDirection: "column", display: "flex"}}>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 48,
                        }}>Your email has been confirmed!</p>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                        }}>You can now return to the app.</p>                
                    </div>
                    : error ? 
                        <p style={{fontFamily: "Inter", marginTop: 24}}>{error}</p>
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!verified}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                        }}>Verifying email...</p>
                    </div>}
                </div>
            </div>
        );
    }

    function IOSView({ verified }) {
        return (
            <div className="App" style={{ 
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
            }}>
    
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 64,
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {verified ? 
                    <div style={{flexDirection: "column", display: "flex", marginLeft: 16, marginRight: 16}}>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 48,
                        }}>Your email has been confirmed!</p>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                        }}>Tap the button below to return to the app and continue with your account creation.</p>    
                        <div style={{
                            display: "flex",
                            height: 58,
                            borderRadius: 29,
                            backgroundColor: "#5448F3",
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginTop: 32,
                        }} onClick={handleToApp}>
                            <p style={{
                                fontFamily: "Inter",
                                fontWeight: 600,
                                fontSize: '16px',
                                color: 'white',
                            }}>Return to the app</p>
                        </div>            
                    </div>

                    : error ? <p style={{fontFamily: "Inter", marginTop: 24}}>{error}</p> 
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!verified}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                        }}>Verifying email...</p>
                    </div>}
                </div>
            </div>
        );
    }

    const Display = device === "iOS" ? IOSView : DesktopView;
    
    return <Display verified={verified} />;
}