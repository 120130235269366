import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import backgroundImage from "../assets/wavy-background.png";
import keepsiesLogo from "../assets/keepsies-logo-purple.svg";
import keepsiesLogoText from "../assets/keepsies-logo-text.svg";
import ClipLoader from "react-spinners/ClipLoader";
import transferSVG from "../assets/svg/transfer-files.svg";
import appStoreSVG from "../assets/svg/download-on-app-store.svg";

export default function TransferPending({device}) {
    const functions = getFunctions();
    const {token} = useParams();
    const [address, setAddress] = React.useState(null);
    const [email, setEmail] = React.useState(null);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        if (!token) return;
        httpsCallable(functions, 'queryToken')({token, type: "propertyTransfer"})
            .then((result) => {
                if (result.data.success) {
                    setAddress(result.data.data.address);
                    setEmail(result.data.data.recipient);
                } else {
                    setAddress(null);
                    setError("Error fetching transfer info");
                }
            })
            .catch((error) => {
                setError("Error fetching transfer info");
            });
    }, [token]);

    function handleToApp() {
        window.location.href = "keepsies://?transferPending=true";
    }
    function handleToAppStore() {
        if (device === "iOS")  window.location.href = "https://apps.apple.com/us/";
        else if (device === "Desktop") window.location.href = "https://www.apple.com/app-store/";
    }
    
    function DesktopView({ }) {
        return (
            <div className="App" style={{ 
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
    
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // height: '350px',
                    width: '450px',
                    minHeight: '350px',
                    backgroundColor: 'rgba(255, 255, 255, 0.95)',
                    borderRadius: '20px',
                    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
                    padding: 16,
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex", marginTop: 48}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {address ? 
                    <div style={{flexDirection: "column", display: "flex", alignItems: "center", padding: 16}}>
                        <img src={transferSVG} alt="Transfer Files" style={{ width: 200, marginTop: 16 }} />
                        <h1 style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 24,
                        }}>Transfer Pending</h1>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                        }}>The previous owner of
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '16px',
                        }}> {address} </span>
                        has sent their home's records to you!</p>  
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            textAlign: 'center',
                        }}>To accept the transfer please download Keepsies from the App Store on your iOS device.</span>
                        <img src={appStoreSVG} alt="Download on the App Store" style={{ height: 58, width: 200, marginTop: 24, marginBottom: 24, cursor: "pointer" }} onClick={handleToAppStore} />
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginBottom: 24,
                            textAlign: 'center',
                        }}>Be sure to register using
                            <span style={{
                                fontWeight: 700,
                            }}> {email}</span>
                        </span>         
                    </div>
                    : error ? 
                        <p style={{fontFamily: "Inter", marginTop: 24}}>{error}</p>
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!address}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>}
                </div>
            </div>
        );
    }

    function IOSView({ }) {
        return (
            <div className="App" style={{ 
                backgroundColor: 'white',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                height: '100vh',
                width: '100vw',
                display: 'flex',
                justifyContent: 'center',
            }}>
    
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 64,
                }}>
                    <div style={{flexDirection: "row", justifyContent: "center", alignItems: "center", display: "flex"}}>
                        <img src={keepsiesLogo} alt="Keepsies Logo" style={{ height: '55px', width: '55px', marginRight: 16 }} />
                        <img src={keepsiesLogoText} alt="Keepsies Logo Text" style={{ height: '55px', width: '200px', marginTop: 4 }} />  
                    </div>
                    {address ? 
                    <div style={{flexDirection: "column", display: "flex", alignItems: "center", padding: 16}}>
                        <img src={transferSVG} alt="Transfer Files" style={{ width: 200, marginTop: 16 }} />
                        <h1 style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '20px',
                            marginTop: 24,
                        }}>Transfer Pending</h1>
                        <p style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginTop: 0,
                            textAlign: 'center',
                        }}>The previous owner of
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 700,
                            fontSize: '16px',
                        }}> {address} </span>
                        has sent their home's records to you!</p>  
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            textAlign: 'center',
                        }}>To accept the transfer please download Keepsies from the App Store on your iOS device.</span>
                        <img src={appStoreSVG} alt="Download on the App Store" style={{ height: 58, width: 200, marginTop: 24, marginBottom: 24, cursor: "pointer" }} onClick={handleToAppStore} />
                        <span style={{
                            fontFamily: "Inter",
                            fontWeight: 400,
                            fontSize: '16px',
                            marginBottom: 24,
                            textAlign: 'center',
                        }}>Be sure to register using
                            <span style={{
                                fontWeight: 700,
                            }}> {email}</span>
                        </span>         
                    </div>
                    : error ? 
                        <p style={{fontFamily: "Inter", marginTop: 24}}>{error}</p>
                    : <div style={{marginTop: 32}}>
                        <ClipLoader
                            color={"#5448F3"}
                            loading={!address}
                            size={32}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>}
                </div>
            </div>
        );
    }

    const Display = device !== "iOS" ? IOSView : DesktopView;
    
    return <Display />;
}