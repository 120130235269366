import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { isDesktop, isAndroid, isIOS } from 'react-device-detect';
import VerifyEmail from './screens/VerifyEmail';
import ResetPassword from './screens/ResetPassword';
import Reroute from './screens/Reroute';
import DownloadData from './screens/DownloadData';
import TransferPending from './screens/TransferPending';
// import "@fontsource/inter"

let device = null;
if        (isDesktop) {device = "Desktop";}
else if   (isAndroid) {device = "Android";}
else if   (isIOS)     {device = "iOS";}

const firebaseConfig = {
  apiKey: "AIzaSyD4FZmr6OnPwjzj5JRqXghcHbQAV_ZCAvI",
  authDomain: "keepsies-firebase.firebaseapp.com",
  projectId: "keepsies-firebase",
  storageBucket: "keepsies-firebase.appspot.com",
  messagingSenderId: "675188728200",
  appId: "1:675188728200:web:931607baaac40ff4835a3d",
  measurementId: "G-ZFYXDFVH1S"
};

const firebase = initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Reroute />} />
        <Route path={"/verify-email/:token"} element={<VerifyEmail device={device} />} />
        <Route path={"/reset-password/:token"} element={<ResetPassword device={device} />} />
        <Route path={"/download-data/:token"} element={<DownloadData device={device} />} />
        <Route path={"/link/transfer-pending/:token"} element={<TransferPending device={device} />} />
      </Routes>
    </Router>
  );
}

export default App;
